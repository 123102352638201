module.exports = [{
    name: 'Crédito Personal',
    amount: {
        min: Number(150000),
        max: Number(10000000)
    },
    plan: {
        from: 6,
        to: 48,
        label: 'meses'
    },
    rate: {
        min: Number(.25),
        max: Number(.35)
    },
    commission: {
        min: Number(.015),
        max: Number(.025)
    },
    guarantee: [
        'Mobiliario',
        'Inmobiliario'
    ],
    cat: Number(29),
    tax: Number(.16)
}, {
    name: 'Crédito Nómina',
    amount: {
        min: Number(30000),
        max: Number(100000)
    },
    plan: {
        from: 6,
        to: 24,
        label: 'meses'
    },
    rate: {
        min: Number(.25),
        max: Number(.35)
    },
    commission: {
        min: Number(.025),
        max: Number(.05)
    },
    guarantee: [
        'Sin garantías'
    ],
    cat: Number(29),
    tax: Number(.16)
}, {
    name: 'Arrendamiento',
    amount: {
        min: Number(300000),
        max: Number(10000000)
    },
    plan: {
        from: 12,
        to: 48,
        label: 'meses'
    },
    rate: {
        min: Number(.19),
        max: Number(.22)
    },
    commission: {
        min: Number(.03),
        max: Number(.05)
    },
    guarantee: [
        'Contrato sobre prenda',
        'Contrato sobre equipo arrendado'
    ],
    cat: Number(29),
    tax: Number(.16)
}, {
    name: 'Factoraje',
    amount: {
        min: Number(250000),
        max: Number(10000000)
    },
    plan: {
        from: 2,
        to: 6,
        label: 'meses'
    },
    rate: {
        min: Number(.20),
        max: Number(.28)
    },
    commission: {
        min: Number(.01),
        max: Number(.025)
    },
    guarantee: [
        'Bienes inmuebles',
        'Bienes muebles',
        'Cesión de derechos',
        'Fideicomisos',
        'Otros'
    ],
    cat: Number(37.7),
    tax: Number(0)
}, {
    name: 'Crédito Automotriz',
    amount: {
        min: Number(200000),
        max: Number(350000)
    },
    plan: {
        from: 12,
        to: 48,
        label: 'meses'
    },
    rate: {
        min: Number(.20),
        max: Number(.28)
    },
    commission: {
        min: Number(.015),
        max: Number(.025)
    },
    guarantee: [
        'Garantía sobre vehículo'
    ],
    cat: Number(29),
    tax: Number(.16)
}, {
    name: 'Créditos PyMEs',
    amount: {
        min: Number(300000),
        max: Number(20000000)
    },
    plan: {
        from: 12,
        to: 36,
        label: 'meses'
    },
    rate: {
        min: Number(.20),
        max: Number(.36)
    },
    commission: {
        min: Number(.01),
        max: Number(.03)
    },
    guarantee: [
        'Bienes inmuebles',
        'Bienes muebles',
        'Cesión de derechos',
        'Fideicomisos',
        'Otros'
    ],
    cat: Number(29),
    tax: Number(0)
}]