$('[data-mask-phone="true"]').each(function() {
    new Cleave($(this), {
        phone: true,
        phoneRegionCode: 'MX'
    });
});

$.validator.setDefaults({
    highlight: element => {
        $(element).closest(':input').addClass('is-invalid').removeClass('is-valid');
    },
    unhighlight: element => {
        $(element).closest(':input').addClass('is-valid').removeClass('is-invalid');
    },
    errorElement: 'span',
    errorClass: 'invalid-feedback',
    validClass: 'valid-feedback',
    errorPlacement: (error, element) => {
        // taken from: https://stackoverflow.com/a/48063602
        let parents = $(element).parents('.form-group');

        let currentErrorElement = parents.find(`#${error.attr('id')}`);

        currentErrorElement.length ? currentErrorElement.html(error) : parents.append(error);
    }
});

window.numberFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    currencyDisplay: 'symbol'
});

window.range = (start, stop, step) => {
    let a = [start]

    let b = start

    while (b < stop) a.push(b += step || 1)

        return (b > stop) ? a.slice(0, -1) : a
}

Array.prototype.sumValues = function() {
    return [].reduce.call(this, (a, b) => {
        if (typeof a === 'string') {
            a = a.replace(/[^\d.-]/g, '') * 1;
        }

        if (typeof b === 'string') {
            b = b.replace(/[^\d.-]/g, '') * 1;
        }

        return Number(a + b);
    }, 0)
}


$('[data-toggle="tooltip"]').tooltip()
$('[data-toggle="popover"]').popover()

$.fn.modal.prototype.constructor.Constructor.Default.backdrop = 'static';
$.fn.modal.prototype.constructor.Constructor.Default.keyboard = false;

Date.prototype.addMonths = function(months = 1) {
    var currentDate = this.getDate();

    this.setMonth(this.getMonth() + +months);

    if (this.getDate() != currentDate) {
        this.setDate(0)
    }

    return this;
}

$.fn.dataTable.Api.register('sum()', function() {
    return this.flatten().reduce((a, b) => {
        if (typeof a === 'string') {
            a = a.replace(/[^\d.-]/g, '') * 1;
        }
        if (typeof b === 'string') {
            b = b.replace(/[^\d.-]/g, '') * 1;
        }

        return Number(a + b);
    }, 0);
});

$('.nav-tabs a').on('shown.bs.tab', e => window.history.pushState ? window.history.pushState(null, null, e.target.hash) : window.location.hash = e.target.hash)

if (document.location.hash.includes('#')) {
    $(`.nav-tabs a[href="${document.location.hash}"]`).tab('show')

    $('html, body').animate({
        scrollTop: $('header').offset().top
    }, 1000)
}

$('.list-group > a').on('click', event => {
    event.preventDefault()

    return false
})