(function() {
    'use strict';

    const form = document.getElementById('contact')

    if (!form) {
        return false;
    }

    const submitButton = form.querySelector('button[type="submit"]')

    let originalSubmitButton = {
        html: $(submitButton).html(),
        classes: $(submitButton).attr('class')
    }

    const prepareGrecaptcha = function() {
        $(submitButton).attr('disabled', true)

        grecaptcha.ready(function() {
            grecaptcha.execute('6LeVXa4ZAAAAACgvMtl2y6x8Kpzv1fSMIhizHEAa', {
                action: 'homepage'
            }).then(function(token) {
                form.querySelector('#token').value = token

                $(submitButton).attr('disabled', false)
            })
        })
    }

    form.addEventListener('submit', function(e) {
        e.preventDefault()
        e.stopPropagation()

        $(submitButton).attr('disabled', true).html(`<span class="spinner-border spinner-border-sm" role="status"></span> Enviando...`);

        $.ajax({
            url: form.action,
            type: form.method || 'POST',
            data: $(form).serialize(),
        }).done(function(response) {
            $('.alert-success').fadeIn('slow').find('strong').html(response)

            form.reset()
        }).fail(function(error) {
            $('.alert-danger').fadeIn('slow').find('strong').html(error.responseText)
        }).always(function() {
            prepareGrecaptcha()

            window.setTimeout(function() {
                $('.alert-success, .alert-danger').fadeOut('slow')
            }, 5000)

            $(submitButton).attr({
                disabled: false,
                class: originalSubmitButton.classes
            }).html(originalSubmitButton.html)
        })
    })

    prepareGrecaptcha()
})()