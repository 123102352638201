$('a[data-toggle="list"]').on('shown.bs.tab', function(e) {
    var currentListItem = e.target;
    var previousListItem = e.relatedTarget;

    switch (currentListItem.hash) {
        case '#list-finish':
            $('.last-step').css('display', 'inherit')
            $('.next-step').css('display', 'none')
            fillReviewLoanRequest()
            break;

        case '#list-design':
            $('.prev-step').addClass('disabled').attr('disabled', true);
            $('.next-step').css('display', 'inherit')
            $('.last-step').css('display', 'none')
            break;

        default:
            $('.prev-step').removeClass('disabled').removeAttr('disabled')
            $('.last-step').css('display', 'none')
            $('.next-step').css('display', 'inherit')
            $('html, body').animate({
                scrollTop: $('#simulator-scroll-target').offset().top
            })
            break;
    }
});

$('.next-step').on('click', event => {
    event.preventDefault();
    let currentActiveListItem = $('.list-group a.active');
    if (currentActiveListItem.next()) {
        if (currentActiveListItem.next()[0].hash === '#list-finish') {
            if ($('#request-form').valid()) {
                currentActiveListItem.next().tab('show')
            }
        } else {
            currentActiveListItem.next().tab('show')
        }
    }
});

$('.prev-step').on('click', event => {
    event.preventDefault();
    let currentActiveListItem = $('.list-group a.active');
    if (currentActiveListItem.prev()) {
        currentActiveListItem.prev().tab('show')
    }
});

if (queryStringParameter = new URL(window.location.href).searchParams.get('goto')) {
    $(`a[href="#${queryStringParameter}"]`).tab('show')
}

function fillReviewLoanRequest() {
    $('#confirmation-form').find('input[name="loan_type"]').val($('#service-type').val())
    $('#confirmation-form').find('input[name="loan_amount"]').val($('#amounts-select option:selected').text())
    $('#confirmation-form').find('input[name="loan_guarantees"]').val($('#service-guarantee').val())
    $('#confirmation-form').find('input[name="loan_months"]').val($('#plan-select').val())
    $('#request-form')
        .serializeArray()
        .forEach(input => $('#confirmation-form').find(`input[name="${input.name}"]`).val(input.value))
}

$('.last-step').on('click', event => {
    event.preventDefault()

    let currentButton = $(event.target)

    let confirmationForm = $('#confirmation-form')

    if (confirmationForm.valid()) {
        currentButton.addClass('disabled').attr('disabled', true).html(`<span class="spinner-border spinner-border-sm" role="status"></span>`)

        $('.prev-step').addClass('d-none')

        $.ajax({
                url: '/enviar-solicitud.php',
                type: 'post',
                data: confirmationForm.serialize(),
            })
            .done(response => {
                let modal = $('.modal')

                modal.find('.alert').html(response)

                modal.modal('show')
            })
            .fail(error => {
                console.log(error)
            })
            .always(response => {
                currentButton.removeClass('disabled').attr('disabled', false).html(`Enviar solicitud <i class="far fa-paper-plane"></i>`)

                $('.prev-step').removeClass('d-none')
            })
    }
});