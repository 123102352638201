const services = require('./data')

const currentOption = {}

let currentRate = 0

let currentSelectedPlan = 0

const table = $('#amounts-table').DataTable({
    destroy: true,
    searching: false,
    info: false,
    pageLength: 12,
    lengthMenu: [12, 24, 36, 48, 60],
    resposive: true,
    language: {
        url: 'https://cdn.datatables.net/plug-ins/1.10.19/i18n/Spanish.json'
    },
    drawCallback: function() {
        var api = this.api();
        $(api.table().footer()).html(
            `
            <tr>
            <th scope="col" colspan="2" class="text-center text-uppercase">Total</th>
            <th scope="col">${numberFormatter.format(api.column(2).data().sum())}</th>
            <th scope="col">${numberFormatter.format(api.column(3).data().sum())}</th>
            <th scope="col">${numberFormatter.format(api.column(4).data().sum())}</th>
            <th scope="col">${numberFormatter.format(api.column(5).data().sum())}</th>
            <th scope="col"></th>
            </tr>
            `
        );
    }
});

const updatePlanSelect = (selector = '#plan-select') => {
    let currentPlan = currentOption.service.plan

    let plans = []

    for (i = currentPlan.from; i <= currentPlan.to; i += 2) {
        plans.push(`<option value="${i} ${currentPlan.label}">${i} ${currentPlan.label}</option>`)
    }

    $(selector).html(
        plans.join('')
    ).trigger('change')
}

const updateGuaranteeSelect = (selector = '#service-guarantee') => {
    $(selector).html(
        currentOption.service.guarantee.map(
            guarantee => `<option value="${guarantee}">${guarantee}</option>`
        )
    )
}

const updateServicesSelect = () => {
    var hasType = searchQueryString('financiamiento');

    return services.map(service => `<option value="${service.name}" ${service.name.includes(hasType) ? 'selected' : ''} data-service='${JSON.stringify(service)}'>${service.name}</option>`)
}

const updateAmountsSelect = (selector = '#amounts-select') => {
    var currentAmount = currentOption.service.amount.min

    let amountSelect = $(selector).selectize({
        create: false,
    })

    var selectize = amountSelect[0].selectize

    selectize.clear()

    selectize.clearOptions()

    while (currentAmount <= currentOption.service.amount.max) {
        selectize.addOption({
            value: currentAmount,
            text: `${numberFormatter.format(currentAmount)} MXN`
        })

        selectize.addItem(selectize.items.length + 1)

        if (currentAmount >= 150000 && currentAmount < 5000000) {
            currentAmount += 10000
        } else if (currentAmount >= 5000000 && currentAmount <= currentOption.service.amount.max) {
            currentAmount += 500000
        } else {
            currentAmount += 5000
        }
    }

    let amountFromQueryString = searchQueryString('monto')

    selectize.setValue(amountFromQueryString in selectize.options ? amountFromQueryString : currentOption.service.amount.min)

    $(selector).trigger('change')
}

const updateProgressBars = (rateSelector = '.progress-bar-rate', commissionSelector = '.progress-bar-commission', catSelector = '.progress-bar-cat') => {
    let {
        max,
        min
    } = currentOption.service.rate

    $('.progress-bar-rate').parent().next('select').html(
        window.range(min * 100, max * 100).map(rate => `<option value="${rate / 100}" ${(searchQueryString('tasa') || (max * 100).toFixed()) == rate ? 'selected' : ''}>${rate}%</option>`).join('')
    ).on('change', event => {
        currentRate = Number(event.target.value)

        updatePlanSelect()

        $(rateSelector).css({
            width: `${(currentRate * 100).toFixed()}%`,
        }).parent().next('span').text(`${(currentRate * 100).toFixed()}%`)
    }).trigger('change')

    $(commissionSelector).css({
        width: `${currentOption.service.commission.max * 100}%`,
    }).parent().next('span').text(`${currentOption.service.commission.max * 100}%`)

    $(catSelector).css({
        width: `${currentOption.service.cat}%`,
    }).parent().next('span').text(`${currentOption.service.cat}%`)
}

const updateTextSpans = (amount, rows, amountSelector = '#text-amount', rateSelector = '#text-rate', commissionSelector = '#text-iva', totalSelector = '#text-total') => {
    $(amountSelector).text(numberFormatter.format(amount))

    $(rateSelector).text(numberFormatter.format(rows.map(x => x[3]).sumValues()))

    $(commissionSelector).text(numberFormatter.format(rows.map(x => x[4]).sumValues()))

    $(totalSelector).text(numberFormatter.format(rows.map(x => x[5]).sumValues()))
}

const searchQueryString = attribute => {
    let url = new URL(window.location.href);

    return url.searchParams.get(attribute);
}

$('#service-type').html(
    updateServicesSelect()
).on('change', event => {
    event.preventDefault()

    currentOption.service = $(`#${event.target.id} option:selected`).data('service')

    updateAmountsSelect()
}).trigger('change');

$('#amounts-select').on('change', event => {
    event.preventDefault();

    updateGuaranteeSelect()

    updateProgressBars()

    updatePlanSelect()
}).trigger('change')

$('#plan-select').on('change', event => {
    event.preventDefault()

    let rows = []

    let [
        months,
        label
    ] = event.target.value.split(" ")

    currentSelectedPlan = months

    let amount = currentAmount = Number($('#amounts-select').val()) * (currentOption.service.commission.max + 1)

    if (currentRate > 0) {
        let monthlyRate = Number(currentRate / 12)

        let payment = Number(amount * (monthlyRate / (1 - Math.pow(1 + monthlyRate, -currentSelectedPlan))))

        for (index = 1; index <= currentSelectedPlan; index++) {
            let interests = Number(0)

            interests = Number(amount * monthlyRate)

            let capital = Number(0)

            capital = Number(payment - interests)

            amount = Number(amount - capital)

            let lastAmount = amount

            let iva = Number(interests * currentOption.service.tax)

            rows.push([
                index,
                new Date().addMonths(index).toLocaleDateString('MX-es'),
                numberFormatter.format(capital),
                numberFormatter.format(interests),
                numberFormatter.format(iva),
                numberFormatter.format(Number(capital + interests + iva)),
                Math.sign(lastAmount) === -1 ? numberFormatter.format(0) : numberFormatter.format(lastAmount),
            ])
        }
    } else {
        let payment = Number(amount / Number(currentSelectedPlan))

        for (index = 1; index <= currentSelectedPlan; index++) {
            amount = amount - payment

            let lastAmount = amount

            rows.push([
                index,
                new Date().addMonths(index).toLocaleDateString('MX-es'),
                numberFormatter.format(payment),
                numberFormatter.format(0),
                numberFormatter.format(0),
                numberFormatter.format(0),
                Math.sign(lastAmount) === -1 ? numberFormatter.format(0) : numberFormatter.format(lastAmount),
            ])
        }
    }

    table.clear().rows.add(
        $(rows)
    ).draw()

    updateTextSpans(currentAmount, rows)
}).trigger('change')