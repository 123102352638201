import validate from 'jquery-validation';

import 'jquery-validation/dist/additional-methods';

import 'jquery-validation/dist/localization/messages_es';

window.Popper = require('popper.js').default;
window.$ = window.jQuery = require('jquery');

require('bootstrap');
require('selectize');

require('datatables.net');
require('datatables.net-bs4');

require('cleave.js');
require('cleave.js/dist/addons/cleave-phone.mx');

require('magnific-popup');

require('owl.carousel');

require('bootstrap-select');

require('bxslider/dist/jquery.bxslider');

require("@fancyapps/fancybox");

require('./vendor/jquery.bgswitcher.min.js');

window.Cookies = require('js-cookie');